<script setup>
import { defineProps, defineModel } from "vue";

let model = defineModel();

const props = defineProps({
  value: Boolean,
  labels: {
    type: Object,
    default: {
      checked: "ON",
      unchecked: "OFF",
    },
  },
  disabled: { type: Boolean, default: false },
  color: {
    type: Object,
    default: {
      checked: "#8CE4BF",
      unchecked: "#454545",
    },
  },
  height: { type: String, default: "25px" },
  width: { type: String, default: "55px" },
});

// If no model is passed used the props.value
// this should only be used in combination with disabled: true as props.value isn't two-way binded
if (model.value === undefined) {
  model = props.value;
}
</script>

<template>
  <label class="switch">
    <input v-model="model" :disabled="props.disabled" type="checkbox" />
    <div class="slider">
      <span class="checked">{{ props.labels["checked"] }}</span>
      <svg viewBox="0 0 100 100">
        <circle fill="currentColor" cx="50" cy="50" r="50" />
      </svg>
      <span class="unchecked">{{ props.labels["unchecked"] }}</span>
    </div>
  </label>
</template>

<style scoped>
.switch {
  color: white;
  & input {
    display: none;
  }
  & span {
    user-select: none;
  }
}

.slider {
  cursor: pointer;
  padding: 2px 4px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: v-bind("props.width");
  height: v-bind("props.height");
  background-color: v-bind("props.color.unchecked");
  border-radius: v-bind("props.height");
  box-sizing: content-box;
  transition: 0.4s;
  & svg {
    justify-self: center;
    height: 76%;
  }
  & span {
    line-height: 1rem;
  }
}

.checked {
  display: none;
}

.checked,
.unchecked {
  align-self: center;
  font-size: 0.8rem;
  padding: 0 0.2rem;
}

input:checked + .slider {
  background-color: v-bind("props.color.checked");
}

input:checked + .slider .checked {
  display: block;
}

input:checked + .slider .unchecked {
  display: none;
}

input:disabled + .slider {
  opacity: 0.6;
}
</style>
