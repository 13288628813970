import { createApp } from 'vue/dist/vue.esm-bundler';

const context = require.context("../components", true, /\.vue$/);

function mountVueComponent(component) {
  const tag = $(component);
  const filename = tag.data("vue-file");
  const classRef = context(filename).default;
  const props = tag.data("vue-props") || {};
  const on = tag.data("vue-on") || {};

  const boundOn = {};
  Object.keys(on).forEach((key) => {
    const functionName = on[key];
    boundOn[key] = () => window[functionName].apply(this, arguments);
  });

  // translations
  if ("t" in props) {
    props.t = JSON.parse(props.t);
  }

  const app = createApp(classRef, props);
  app.directive("click-outside", {
    mounted(el, binding) {
      el.clickOutsideEvent = (event) => {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      };
      document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted(el) {
      document.body.removeEventListener("click", el.clickOutsideEvent);
    }
  });
  app.mount(tag[0]);
}

$(() => {
  const vueComponents = $(".vue-component");
  Array.from(vueComponents).forEach(component => mountVueComponent(component));
});
