<template>
  <div ref="wrapper">
    <div v-if="renderAs == 'banner'">
    <transition name="fade">
      <div v-if="mounted && !consentManaged">
        <div v-if="explicitConsent" class="banner fixed-bottom">
          <div class="container py-2 py-md-4">
              <span class="h4 d-block mb-0 mb-md-1">{{ t.header }}</span>
              <p>
               {{ t.eu_consent }}
                <a v-if="showRejectAll" class="banner-link" href="#" data-toggle="modal" data-target="#consentModal">{{ t.open_settings }}</a>
              </p>
              <div class="buttons d-flex justify-content-between justify-content-md-center">
                <button type="button" v-if="showRejectAll" class="btn mr-2" :class="bannerButtonStyle" @click.prevent="rejectAll">{{ t.reject_all }}</button>
                <button type="button" v-if="!showRejectAll" class="btn btn-link btn-link-secondary" data-toggle="modal" data-target="#consentModal">{{ t.open_settings }}</button>
                <button type="button" class="btn" :class="bannerButtonStyle" @click.prevent="consentAll">{{ t.accept_all }}</button>
              </div>
          </div>
        </div>
        <div v-else class="banner fixed-bottom">
          <div class="container py-3 d-flex justify-content-between">
            <p class="mb-0" v-html="t.consent"></p>
            <i class="fas fa-times icon-close" @click="consentAll"></i>
          </div>
        </div>
      </div>
    </transition>
    </div>
    <div v-else>
      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#consentModal">{{ t.manage_cookies }}</button>
    </div>
    <div id="consentModal" class="modal" data-keyboard="false" data-backdrop="static">
      <div  class="modal-dialog modal-dialog-md-centered">
        <div class="modal-content">
          <div class="modal-header border-0 pb-0 px-1">
              <div class="container">
                <h5 class="modal-title">{{ t.modal_header }}</h5>
              </div>
          </div>
           <div class="cookie-consent-info py-2 py-md-3 px-1">
              <div class="container">
                <p class="mb-0" v-html="t.modal_cookie_info"></p>
              </div>
            </div>
          <div class="modal-body px-1">
            <div class="container">
              <div class="row consent-class">
                <div class="col-9 col-md-10 pr-0">
                  <div class="d-flex align-items-center">
                    <span class="consent-class-header d-block">{{ t.essential.header}}</span>
                    <span class="always-on ml-2 text-uppercase font-weight-bold d-block">{{ t.always_on }}</span>
                  </div>
                  <p>{{ t.essential.description }}</p>
                </div>
                <div class="col-3 col-md-2 d-flex justify-content-center align-items-center">
                  <toggle-button :value="true" :labels="switchLabels" :disabled="true" :color="switchColors" width="55px" height="25px" />
                </div>
              </div>
              <div class="row consent-class">
                <div class="col-9 col-md-10 pr-0">
                  <span class="consent-class-header block">{{ t.functional.header }}</span>
                  <p>{{ t.functional.description }}</p>
                </div>
                <div class="col-3 col-md-2 d-flex justify-content-center align-items-center">
                  <toggle-button v-model="selection.functional" :labels="switchLabels" :color="switchColors" width="55px" height="25px" />
                </div>
              </div>
              <div class="row consent-class">
                <div class="col-9 col-md-10 pr-0">
                  <span class="consent-class-header block">{{ t.performance.header }}</span>
                  <p>{{ t.performance.description }}</p>
                </div>
                <div class="col-3 col-md-2 d-flex justify-content-center align-items-center">
                  <toggle-button v-model="selection.performance" :labels="switchLabels" :color="switchColors" width="55px" height="25px" />
                </div>
              </div>
              <div class="row consent-class">
                <div class="col-9 col-md-10 pr-0">
                  <span class="consent-class-header block">{{ t.targeting.header }}</span>
                  <p>{{ t.targeting.description }}</p>
                </div>
                <div class="col-3 col-md-2 d-flex justify-content-center align-items-center">
                  <toggle-button v-model="selection.targeting" :labels="switchLabels" :color="switchColors" width="55px" height="25px" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center w-full flex-wrap flex-md-nowrap">
            <button type="button" class="btn btn-outline-secondary btn-block mt-0 mr-0 mr-md-1 mb-2 mb-md-0 ml-0" data-dismiss="modal" @click="consentSelected">{{ t.accept_selected }}</button>
            <button type="button" class="btn btn-block mt-0 mr-0 mr-md-1 mb-2 mb-md-0 ml-0" :class="modalRejectButtonStyle" data-dismiss="modal" @click="rejectAll">{{ t.reject_all }}</button>
            <button type="button" class="btn btn-success btn-block mt-0 ml-0 mb-0" data-dismiss="modal" @click="consentAll">{{ t.accept_all }}</button>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from "../src/cookie.js"
import Hotjar from "../src/hotjar.js"
import EventBus from "../src/event_bus"
import ToggleButton from "./toggle-button.vue"
export default {
  props: {
    renderAs: String,
    hotjarEnabled: Boolean,
    explicitConsent: Boolean,
    country: String,
    ip: String,
    consentShareSites: Array,
    cookiePolicyVersion: String,
    showRejectAll: Boolean,
    t: Object,
  },

  data() {
    return {
      mounted: false,
      timestamp: Date.now(),
      acceptedCookiePolicyVersion: null,
      consent: {
        managed: false,
        functional: false,
        performance: false,
        targeting: false,
      },
      selection: {
        managed: true,
        functional: false,
        performance: false,
        targeting: false,
      },
      switchLabels: {
        checked: "ON",
        unchecked: "OFF"
      },
      switchColors: {
        checked: "#8CE4BF",
        disabled: "#8CE4BF",
        unchecked: "#454545",
      },
      cookieName: "consent",
      cookieMaxAge: 365
    }
  },

  computed: {
    bannerButtonStyle() {
      return {
        "btn-success": !this.showRejectAll,
        "btn-banner-secondary": this.showRejectAll
      }
    },

    modalRejectButtonStyle() {
       return {
        "btn-outline-secondary": !this.showRejectAll,
        "btn-success": this.showRejectAll
      }
    },

    consentManaged() {
      return this.consent.managed === true
    },

    functionalAccepted() {
      return this.consent.functional === true
    },

    performanceAccepted() {
      return this.consent.performance === true
    },

    targetingAccepted() {
      return this.consent.targeting === true
    },

    options() {
      return Object.keys(this.consent)
    },

    cookieData() {
      const data =  {
        ip: this.ip,
        origin: "remove.bg",
        country: this.country,
        timestamp: this.timestamp,
        options: this.options,
        consent: this.consent,
        cookiePolicyVersion: this.cookiePolicyVersion,
      }

      return Buffer.from(JSON.stringify(data)).toString("base64")
    }
  },

  mounted() {
    this.initCookie()
    this.loadConsentFromCookie()
    this.checkAcceptedPolicyVersion()
    this.updateSelection()
    this.updateGTagPreferences()
    this.loadHotjar()
    this.updateLoginUrl()
    EventBus["consent-managed"] = () => {
      this.consent.managed = true
    }

    this.mounted = true
  },

  methods: {
    checkAcceptedPolicyVersion() {
      if(this.cookiePolicyVersion == this.acceptedCookiePolicyVersion) return

      if(!this.explicitConsent) {
        this.autoConsentAll()
      } else {
        this.resetAll()
      }

      this.saveConsentToCookie()
    },

    initCookie() {
      if (Cookie.get(this.cookieName) != undefined) return

      if(!this.explicitConsent) {
       this.autoConsentAll()
      } else {
        this.consent.managed = false
      }

      this.saveConsentToCookie()
    },

    updateSelection() {
      if(!this.consentManaged) return

      this.selection = this.consent
    },

    consentSelected() {
      this.consent = this.selection

      this.saveChoices()
    },

    resetAll() {
      this.consent.managed = false
      this.consent.functional = false
      this.consent.performance = false
      this.consent.targeting = false
    },

    consentAll() {
      this.consent.managed = true
      this.consent.functional = true
      this.consent.performance = true
      this.consent.targeting = true
      this.selection = this.consent

      this.saveChoices()
    },

    autoConsentAll() {
      this.consent.managed = false
      this.consent.functional = true
      this.consent.performance = true
      this.consent.targeting = true
    },

    rejectAll() {
      this.consent.managed = true
      this.consent.functional = false
      this.consent.performance = false
      this.consent.targeting = false
      this.selection = this.consent

      this.saveChoices()
    },

    saveChoices() {
      this.saveConsentToCookie()
      this.updateGTagPreferences()
      this.loadHotjar()
      this.shareConsent()
      EventBus["consent-managed"]()
    },

    shareConsent() {
      this.consentShareSites.forEach(site => {
        let iframe = document.createElement("iframe")
        const url = `${site}/cookies/update_consent?value=${this.cookieData}`

        iframe.style.display = "none"
        iframe.referrerPolicy = "unsafe-url"
        iframe.src = url


        iframe.onload = () => {
          iframe.parentNode.removeChild(iframe)
        }

        this.$refs.wrapper.appendChild(iframe)
      })

      this.updateLoginUrl()
    },

    updateLoginUrl() {
      const signInButton = document.getElementById("sso-sign-in-btn")
      if(!signInButton) return

      let url = new URL(signInButton.href)
      url.searchParams.set("cookie_consent", this.cookieData)
      signInButton.href = url
    },

    updateGTagPreferences() {
      if (!window.gtag) return
      const performanceState = this.performanceAccepted ? "granted" : "denied"
      const targetingState = this.targetingAccepted ? "granted" : "denied"

      window.gtag("consent", "update", {
        "analytics_storage": performanceState,
        "ad_storage": targetingState
      })
    },

    loadHotjar() {
      if(!this.hotjarEnabled) return
      if(!this.performanceAccepted) return
      if(window.hj) return

      Hotjar.load()
    },

    loadConsentFromCookie() {
      const cookie = Cookie.get(this.cookieName)

      try {
        const decodedCookie = JSON.parse(Buffer.from(cookie, "base64").toString("ascii"))
        this.consent = decodedCookie.consent
        this.acceptedCookiePolicyVersion = decodedCookie.cookiePolicyVersion
      } catch(e) {
         this.consent.managed = false
         this.saveConsentToCookie()
      }
    },

    saveConsentToCookie() {
      Cookie.set(this.cookieName, "essential", this.cookieData, { expires: this.cookieMaxAge, secure: true, sameSite: "None" })
    }
  },
  components: { ToggleButton }
}
</script>

<style lang="scss" scoped>
.banner, .modal-footer {
  background-color: #F2FAFB;
  color: #454545;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.24)
}

.h4, h5 {
  font-size: 1.3rem;

  @media(max-width: 767px) {
    font-size: 0.9rem;
  }
}

 @media(max-width: 767px) {
   p {
     font-size: 0.75rem;
   }
  }

.btn-banner-secondary {
  color: #454545;
  background-color: #DDDFE1;
  border-color: #DDDFE1;
}

.modal {
  color: #454545;

  .modal-header {
    background-color: #F8F9FA;
  }

  .modal-dialog {
    max-width: 650px;
  }
}

.modal-dialog-md-centered {
  @media(min-width: 769px) {
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);
  }

  &:before {
    display: block;
    height: min-content;
    content: "";
  }
}

.always-on {
  font-size: 0.8rem;
  color: #BAC0C4;
}

.banner-link {
  color: #454545;
  text-decoration: underline;
}

.consent-class {
  .consent-class-header {
    font-weight: bold;
    font-size: 1.1rem;
  }

  p {
    font-size: 0.9rem;
  }
}

@media(max-width: 767px) {
  .modal-body {
    max-height: 40vh;
    overflow-y: auto;
  }
}

@media(max-width: 320px) {
  .buttons button {
    font-size: 0.75rem;
  }
}

.cookie-consent-info {
  background-color: #F9FAFA;
}

.btn-link-secondary {
  color: #212529;
  font-weight: bold;
}

.icon-close {
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
